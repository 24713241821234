
import { soundData } from './sounds-effects';
import './App.css';
import DrumButtons from './DrumPad';
import DrumMachine from './DrumMachine';

function App() {
  return (
    <div className='App'>
      <h1 className='text-center'>Drum machine</h1>
        <DrumMachine />
      </div>
  );
}

export default App;
