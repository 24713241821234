
    export const soundData= [
        {
            "soundEffect": "https://s3.amazonaws.com/freecodecamp/drums/Heater-1.mp3",
            "key": "Q"
        },
        {
            "soundEffect": "https://s3.amazonaws.com/freecodecamp/drums/Heater-2.mp3",
            "key": "W"
        },
        {
            "soundEffect": "https://s3.amazonaws.com/freecodecamp/drums/Heater-3.mp3",
            "key": "E"
        },
        {
            "soundEffect": "https://s3.amazonaws.com/freecodecamp/drums/Heater-4_1.mp3",
            "key": "A"
        },
        {
            "soundEffect": "https://cdn.freecodecamp.org/testable-projects-fcc/audio/Heater-6.mp3",
            "key": "S"
        },
        {
            "soundEffect": "https://cdn.freecodecamp.org/testable-projects-fcc/audio/Dsc_Oh.mp3",
            "key": "D"
        },
        {
            "soundEffect": "https://cdn.freecodecamp.org/testable-projects-fcc/audio/Kick_n_Hat.mp3",
            "key": "Z"
        },
        {
            "soundEffect": "https://cdn.freecodecamp.org/testable-projects-fcc/audio/RP4_KICK_1.mp3",
            "key": "X"
        },
        {
            "soundEffect":'https://cdn.freecodecamp.org/testable-projects-fcc/audio/Cev_H2.mp3',
            "key":'Z'
        }
    ]








