import { useState } from "react";
import { soundData } from "./sounds-effects";
import DrumPad from "./DrumPad";

const DrumMachine = () => {
    const [display, setDisplay] = useState('');
  
    const handlePlaySound = (e) => {
      setDisplay(e);
    };
  
    return (
      <div id="drum-machine">
        <div id="display">{display}</div>
        <div className="drum-pads">
          {soundData.map((sound) => (
            
            <DrumPad
              key={sound.key}
              keyTrigger={sound.key}
              sound={sound.soundEffect}
              onPlaySound={handlePlaySound}
            />
          ))}
        </div>
      </div>
    );
  };
  
  export default DrumMachine;