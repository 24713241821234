import React from 'react';

const DrumPad = ({ keyTrigger, sound, onPlaySound }) => {
  
  const playSound = () => {
    const audio = document.getElementById(keyTrigger);
    audio.currentTime = 0;
    audio.play();
    onPlaySound(sound.name);
  };

  const handleKeyPress = (e) => {
    if (e.key.toUpperCase() === keyTrigger) {
      playSound();
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <div className="drum-pad" onClick={playSound}>
      {keyTrigger}
      <audio id={keyTrigger} src={sound} className="clip"></audio>
    </div>
  );
};

export default DrumPad;
